import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';

function Footer() {
  return (
    <>
      <Box paddingBlock={1} display="flex" justifyContent="center">
        <Typography
          variant="caption"
          color="InactiveCaptionText"
          textAlign="center"
        >
          Copyright © ZetaOne {dayjs().format('YYYY')}
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
