import { push } from '@lagunovsky/redux-react-router';
import dayjs from 'dayjs';
import ky, { KyResponse } from 'ky';
import { put, select, take } from 'redux-saga/effects';
import { getApi } from '../../helpers/api';
import rpcCall from '../../sagas/rpc';
import { notifierActions } from '../notifier/notifier-slice';
import { GetInvoicesResponse, appActions } from './app-slice';

const local = false;

function* watchErrors() {
  while (true) {
    const { payload: message } = yield take(appActions.setError);
    yield put(
      notifierActions.enqueue({
        message,
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* watchLogin() {
  while (true) {
    const {
      payload: { eid, docNumber },
    } = yield take(appActions.login);

    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: `/customer-app/login`,
        local,
      });

      const { error, journey, customer } = yield rpcCall({
        url,
        method: 'post',
        headers: { ...headers, 'X-Zeta-Eid': eid },
        payload: { docNumber },
        ...opts,
      });
      if (error) {
        yield put(appActions.loginFail());
        yield put(appActions.setError(error));
      } else {
        yield put(appActions.loginSuccess({ journey, customer }));
        yield put(push(`/${eid}/${docNumber}`));
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.loginFail());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSendQrcode() {
  while (true) {
    yield take(appActions.sendQrcode);

    try {
      const {
        journey: { jid },
        eid,
      } = yield select((state) => state.app);
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: `/customer-app/send-qr-code`,
        local,
      });
      const { error } = yield rpcCall({
        url,
        method: 'post',
        headers: { ...headers, 'X-Zeta-Eid': eid },
        payload: { jid },
        ...opts,
      });
      if (error) {
        yield put(appActions.setError(error));
      } else {
        yield put(appActions.sendQrcodeSuccess());
        yield put(
          notifierActions.enqueue({
            message: 'QRCode(s) enviados com sucesso',
            options: {
              variant: 'success',
            },
          })
        );
        yield put(push(`/${eid}/finish`));
      }
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.sendQrcodeFail());
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetInvoices() {
  while (true) {
    const { payload: jid } = yield take(appActions.getInvoices);

    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: `/customer-app/get-invoices/${jid}`,
        local,
      });

      const response: KyResponse = yield ky(url, {
        method: 'get',
        headers: { ...headers },
        ...opts,
      });
      const data: GetInvoicesResponse = yield response.json();

      const pendingInvoices = [
        ...data.invoices.filter(
          (v) =>
            v.paymentStatus === 'ON_SCHEDULE' || v.paymentStatus === 'OVERDUE'
        ),
      ].sort(
        ({ invoiceDate: a }, { invoiceDate: b }) =>
          dayjs(a).valueOf() - dayjs(b).valueOf()
      );

      const paidInvoices = [
        ...data.invoices.filter((v) => v.paymentStatus === 'PAID'),
      ].sort(
        ({ invoiceDate: a }, { invoiceDate: b }) =>
          dayjs(a).valueOf() - dayjs(b).valueOf()
      );

      yield put(appActions.setPendingInvoices(pendingInvoices));
      yield put(appActions.setPaidInvoices(paidInvoices));
      if (pendingInvoices.length === 0)
        yield put(appActions.setIsPaidInvoicesExpanded(true));
      else yield put(appActions.setIsPaidInvoicesExpanded(false));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.getInvoicesFail());
      yield put(appActions.finishLoading());
    }
  }
}

export default [watchErrors, watchLogin, watchSendQrcode, watchGetInvoices];
