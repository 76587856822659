import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Customer = {
  name: string;
  phone: string;
};

type Journey = {
  jid: string;
};

type AppState = {
  eid?: string;
  docNumber?: string;
  isLoading: boolean;
  isError: boolean;
  error: string;
  notifications: string[];
  logged: boolean;
  customer?: Customer;
  journey?: Journey;
  qrCodeSent: boolean;
  pendingInvoices: Invoice[];
  paidInvoices: Invoice[];
  isPaidInvoicesExpanded: boolean;
};

type LoginActionPayload = {
  eid?: string;
  docNumber: string;
};

type PaymentStatus = 'OVERDUE' | 'ON_SCHEDULE' | 'PAID';

type Invoice = {
  invoiceDate: string;
  customerDocNumber: string;
  customerName: string;
  enterpriseName: string;
  invoiceId: string;
  paymentStatus: PaymentStatus;
  invoiceValue: number;
  imgUrl: string;
  qrcode: string;
  pixResultAt: number;
};

export type GetInvoicesResponse = {
  invoices: Invoice[];
  date: string;
};

const initialState: AppState = {
  isLoading: false,
  isError: false,
  error: '',
  notifications: [],
  logged: false,
  qrCodeSent: false,
  pendingInvoices: [],
  paidInvoices: [],
  isPaidInvoicesExpanded: false,
};
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setError: (state, { payload }: PayloadAction<string>) => {
      state.isError = true;
      state.error = payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    setEid: (state, { payload }: PayloadAction<string>) => {
      state.eid = payload;
    },
    login: (
      state,
      { payload: { eid, docNumber } }: PayloadAction<LoginActionPayload>
    ) => {
      state.eid = eid;
      state.docNumber = docNumber;
      state.qrCodeSent = false;
    },
    loginSuccess: (
      state,
      {
        payload: { customer, journey },
      }: PayloadAction<{ journey: Journey; customer: Customer }>
    ) => {
      state.logged = true;
      state.customer = customer;
      state.journey = journey;
    },
    loginFail: (state) => {
      state.logged = false;
      state.customer = undefined;
      state.journey = undefined;
    },
    sendQrcode: () => {},
    sendQrcodeSuccess: (state) => {
      state.qrCodeSent = true;
    },
    sendQrcodeFail: () => {},
    getInvoices: (_state, _action: PayloadAction<string>) => {},
    setPendingInvoices: (state, { payload }: PayloadAction<Invoice[]>) => {
      state.pendingInvoices = payload;
    },
    setPaidInvoices: (state, { payload }: PayloadAction<Invoice[]>) => {
      state.paidInvoices = payload;
    },
    getInvoicesFail: (state) => {
      state.pendingInvoices = [];
      state.paidInvoices = [];
    },
    setIsPaidInvoicesExpanded: (state, { payload }: PayloadAction<boolean>) => {
      state.isPaidInvoicesExpanded = payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
