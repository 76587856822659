import {
  createRouterMiddleware,
  createRouterReducerMapObject,
} from '@lagunovsky/redux-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';

import app from '../features/app/app-slice';
import notifier from '../features/notifier/notifier-slice';
import rootSaga from '../sagas';

const saga = createSagaMiddleware();
export const history = createBrowserHistory();
const router = createRouterReducerMapObject(history);
const routerMiddleware = createRouterMiddleware(history);

const store = configureStore({
  reducer: {
    ...router,
    app,
    notifier,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: [
          'notifier.notifications',
          'notifier.visibleNotifications',
        ],
        ignoredActions: ['notifier/enqueue'],
      },
    })
      .prepend(routerMiddleware)
      .concat(saga),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

saga.run(rootSaga);
export default store;
