import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import Footer from '../../components/Footer';
import Invoices from './Invoices';
import PageError from './PageError';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<PageError />} />
        <Route path="/:jid" element={<Invoices />} />
        {/* <Route path="/:eid" element={<Doc />} />
        <Route path="/:eid/:docNumber" element={logged ? <Main /> : <Doc />} />
        <Route path="/:eid/finish" element={logged ? <Finish /> : <Doc />} /> */}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
