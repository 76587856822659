'use strict';
import { doc, onSnapshot } from 'firebase/firestore';
import ky, { KyResponse } from 'ky';
import { EventChannel, eventChannel } from 'redux-saga';
import { call, ChannelTakeEffect, take } from 'redux-saga/effects';
import { db } from '../helpers/firebase';

function createRpcChannel(id: string) {
  return eventChannel((emitter) => {
    const unsubscribe = onSnapshot(doc(db, 'rpc', id), (doc) => {
      if (!doc.exists) return;
      const { result } = doc.data() ?? {};
      if (!result) return;
      emitter(result);
    });
    return unsubscribe;
  });
}

type Rpc = {
  url: string;
  headers: Record<string, string>;
  payload: any;
  method: string;
};

function* rpcCall({ url, headers, payload, method = 'get', ...opts }: Rpc) {
  try {
    const response: KyResponse = yield ky(url, {
      method,
      json: payload,
      headers,
      ...opts,
    });
    const { rpcid } = yield response.json();
    const chan: EventChannel<any> = yield call(createRpcChannel, rpcid);
    const result: ChannelTakeEffect<any> = yield take(chan);
    chan.close();
    return result;
  } catch (e) {
    return { error: (e as any).message };
  }
}

export default rpcCall;
