import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { appActions } from './app-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <div>
    <Typography variant="caption" color="textSecondary">
      {label}:{' '}
    </Typography>
    <Typography variant="body1">{children ?? value}</Typography>
  </div>
);

function Invoices() {
  const { isLoading, pendingInvoices, paidInvoices, isPaidInvoicesExpanded } =
    useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const { jid } = useParams();
  const [isCopied, setIsCopied] = useState(-1);

  useEffect(() => {
    if (jid) dispatch(appActions.getInvoices(jid));
  }, [jid]);

  return (
    <>
      <Header />

      {isLoading ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {pendingInvoices.length === 0 ? (
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              p={2}
            >
              <Alert severity="success">
                Não existe nenhuma fatura em aberto!
              </Alert>
            </Stack>
          ) : (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              paddingInline={1}
              gap={2}
            >
              {pendingInvoices.map((i, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{ width: '100%', maxWidth: '400px' }}
                >
                  <Stack
                    p={2}
                    direction="column"
                    justifyContent="center"
                    gap={1}
                  >
                    <Detail label="Identificação" value={i.invoiceId} />
                    <Detail
                      label="Pagador"
                      value={`${i.customerName} - ${i.customerDocNumber}`}
                    />
                    <Detail label="Recebedor" value={i.enterpriseName} />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Detail
                        label="Valor"
                        value={format(i.invoiceValue, 'money')}
                      />
                      <Detail
                        label="Vencimento"
                        value={format(i.invoiceDate, 'date')}
                      />
                    </Stack>
                    {i.paymentStatus === 'OVERDUE' && (
                      <Alert variant="outlined" severity="warning">
                        Fatura Vencida!
                      </Alert>
                    )}
                    <Stack
                      maxWidth="400px"
                      direction="row"
                      justifyContent="center"
                    >
                      <img src={i.imgUrl} width="90%" />
                    </Stack>
                    <Button
                      sx={{ height: '50px' }}
                      onClick={() => {
                        copy(i.qrcode);
                        setIsCopied(index);
                      }}
                      variant="contained"
                    >
                      <b>Copiar Código</b>
                    </Button>
                    {isCopied === index && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={0.5}
                      >
                        <Typography
                          variant="caption"
                          align="center"
                          color="success.main"
                        >
                          Código copiado
                        </Typography>
                        <CheckIcon fontSize="small" color="success" />
                      </Stack>
                    )}
                  </Stack>
                </Card>
              ))}
            </Stack>
          )}
          {paidInvoices.length > 0 && (
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              paddingInline={1}
              gap={2}
            >
              {pendingInvoices.length > 0 ? (
                <Box marginTop={2}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      dispatch(
                        appActions.setIsPaidInvoicesExpanded(
                          !isPaidInvoicesExpanded
                        )
                      )
                    }
                  >
                    {isPaidInvoicesExpanded
                      ? 'Ocultar as faturas pagas'
                      : 'Ver as faturas pagas'}
                  </Button>
                </Box>
              ) : (
                <Typography>Faturas pagas:</Typography>
              )}
              <Collapse
                in={isPaidInvoicesExpanded}
                sx={{
                  width: '100%',
                  maxWidth: '400px',
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  {paidInvoices.map((i, index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{ width: '100%', maxWidth: '400px' }}
                    >
                      <Stack
                        p={2}
                        direction="column"
                        justifyContent="center"
                        gap={1}
                      >
                        <Detail label="Identificação" value={i.invoiceId} />
                        <Detail
                          label="Pagador"
                          value={`${i.customerName} - ${i.customerDocNumber}`}
                        />
                        <Detail label="Recebedor" value={i.enterpriseName} />
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Detail
                            label="Valor"
                            value={format(i.invoiceValue, 'money')}
                          />
                          <Detail
                            label="Vencimento"
                            value={format(i.invoiceDate, 'date')}
                          />
                        </Stack>
                        <Alert variant="outlined" severity="success">
                          Fatura Paga em {format(i.pixResultAt, 'date')}
                        </Alert>
                      </Stack>
                    </Card>
                  ))}
                </Stack>
              </Collapse>
            </Stack>
          )}
        </>
      )}
    </>
  );
}

export default Invoices;
