'use strict';
import { app } from './firebase';
const API_VERSION = 'v1';
const AUTH_TOKEN = 'Q$WazpNmb#Y&MhDOJ@9&h4AX^Y&GK&tJ841^Ej5r#@SVmZ5J@g';

export const getBaseUrl = (local: boolean) => {
  if (local) return 'http://localhost:3001';
  const { projectId } = app.options;
  let baseUrl = '';
  switch (projectId) {
    case 'condomi-prod':
      baseUrl = `https://api.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-stg':
      baseUrl = `https://api-h.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-dev':
      baseUrl = `https://api-d.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-dev2':
      baseUrl = `https://api-d2.zetaone.com.br/${API_VERSION}`;
      break;
  }
  console.log(baseUrl);
  return baseUrl;
};

export const getApi = async ({
  path,
  local = false,
}: {
  path: string;
  local: boolean;
}) => {
  let headers = {
    'X-Zeta-Token': AUTH_TOKEN,
  };
  let baseUrl = getBaseUrl(local);
  const url = `${baseUrl}${path}`;
  return { url, headers, timeout: 50000 };
};
