import { Stack, Typography } from '@mui/material';
import Header from '../../components/Header';

function PageError() {
  return (
    <>
      <Header />
      <Stack direction="column" justifyContent="center" p={4} gap={4}>
        <Typography variant="h4" textAlign="center">
          Página não encontrada
        </Typography>
      </Stack>
    </>
  );
}

export default PageError;
