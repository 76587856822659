import { Box } from '@mui/material';
import { ReactEventHandler, SyntheticEvent } from 'react';
import { useParams } from 'react-router-dom';
const addDefaultSrc: ReactEventHandler<HTMLImageElement> = (
  e: SyntheticEvent
) => {
  (e.target as HTMLImageElement).src =
    'https://storage.googleapis.com/assets-zeta/zeta.png';
};

function Header() {
  const { eid } = useParams();
  const logo = `https://storage.googleapis.com/assets-zeta/${eid}.png`;

  return (
    <Box
      paddingBottom={1}
      display="flex"
      justifyContent="center"
      sx={{
        '@media only screen and (max-width: 600px)': {
          '& img': { maxWidth: '100px' },
        },
        '@media only screen and (min-width: 600px)': {
          '& img': { maxWidth: '100px' },
        },
      }}
    >
      <img onError={addDefaultSrc} src={logo} alt="Logo" />
    </Box>
  );
}

export default Header;
